body {
  font-size: 12px;
}

input, button {
  font-size: 12px;
}

a.link {
  color: #0066cc;
  text-decoration: underline;
  cursor: pointer;
}
a.link:visited {
  color: #0066cc;
}

.current a.link {
  color: #000000;
  font-weight: bold;
  text-decoration: none;
  pointer-events: none;
}

.errors {
  width: 100%;
  color: #CC0000;
  text-align: left;
}

.error {
  color: #ff0000;
}

.warning {
  background-color: #ff0000 !important;
  color: #ffffff !important;
}

.success {
  width: 100%;
  color: #0000CC;
  text-align: left;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

input[type=radio] {
  margin: 0;
}
input[type=radio] + label {
  display: inline-block;
  min-width: 40px;
  margin: 0 8px;
  text-align: left;
}

input[type=text].wide {
  width: 100%;
}

textarea {
  resize: vertical;
  width: 100%;
}

select {
  background-color: #ffffff;
  border: 1px solid #808080;
}

.button {
  border: 1px solid #000000;
}
.button.medium {
  width: 100px;
}
.button.wide {
  width: 180px;
}

.required-mark::after {
  content: " *";
  color: #ff0000;
  vertical-align: middle;
}

.error_page {
  width: 720px;
  margin: 24px auto;
  border: 1px solid #9f9063;
}
.error_page .app_name {
  width: 100%;
  background-color: #ffcc00;
  font-size: 18px;
  text-align: center;
}
.error_page .error_title {
  padding: 12px;
  font-size: 21px;
}
.error_page .error_message {
  padding: 12px;
  font-size: 14px;
}
.error_page .error_debug {
  padding: 12px;
  font-size: 12px;
}
body {
  font-size: $font-normal;
}

input, button {
  font-size: $font-normal;
}

// a-link
a.link {
  color: $color-link;
  text-decoration: underline;
  cursor: pointer;

  &:visited {
    color: $color-link;
  }
}
.current {
  a.link {
    color: $color-black;
    font-weight: bold;
    text-decoration: none;
    pointer-events: none;
  }
}

// errors
.errors {
  width: 100%;
  color: #CC0000;
  text-align: left;
}

.error {
  color: $color-red;
}

// warning
.warning {
  background-color: $color-red !important;
  color: $color-white !important;
}

// success
.success {
  width: 100%;
  color: #0000CC;
  text-align: left;
}

// bold
.bold {
  font-weight: bold;
}

// italic
.italic {
  font-style: italic;
}

// radio
input[type=radio] {
  margin: 0;

  & + label {
    display: inline-block;
    min-width: 40px;
    margin: 0 8px;
    text-align: left;
  }
}

// text
input[type=text] {
  &.wide {
    width: 100%;
  }
}

// textarea
textarea {
  resize: vertical;
  width: 100%;
}

// select
select {
  background-color: $color-white;
  border: 1px solid $color-dark-grey;
}

// button
.button {
  border: 1px solid $color-black;

  &.medium {
    width: 100px;
  }

  &.wide {
    width: 180px;
  }
}

.required-mark::after {
  content: " *";
  color: $color-red;
  vertical-align: middle;
}

// error page
.error_page {
  width: 720px;
  margin: 24px auto;
  border: 1px solid $color-list-border;

  .app_name {
    width: 100%;
    background-color: $color-yellow;
    font-size: $font-extra-large;
    text-align: center;
  }

  .error_title {
    padding: 12px;
    font-size: 21px;
  }

  .error_message {
    padding: 12px;
    font-size: $font-large;
  }

  .error_debug {
    padding: 12px;
    font-size: $font-normal;
  }
}

// Color
$color-black: #000000;
$color-white: #ffffff;
$color-red: #ff0000;
$color-blue: #0000cc;
$color-yellow: #ffcc00;
$color-green: #99cc00;
$color-grey: #cccccc;
$color-light-grey: #f4f4f4;
$color-dark-grey: #808080;
$color-orange: #ffa41b;

$color-link: #0066cc;
$color-list-border: #9f9063;
$color-list-header: #fee69e;
$color-list-headline: #99ccff;
$color-mask: #cccccc99;
$color-subtotal: #fff5e8;

// Font
$font-normal: 12px;
$font-large: 14px;
$font-extra-large: 18px;
